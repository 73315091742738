const routes = filterByKey([
    {
        path: '/',
        component: () => import('@/views/index/index'),
        name: "index",
        meta: {
            title: '首页',
            classify: 'index',
            isShow: 1,
        },
    },
    {
        path: '/course/:id',
        component: () => import('@/views/course/index'),
        name: "course",
        meta: {
            title: '课程',
            classify: 'course',
            isShow: 1,
        }
    },
    {
        path: '/course/subclass/:id',
        component: () => import('@/views/course/subclass'),
        name: "course",
        meta: {
            title: '课程',
            classify: 'course',
            isShow: 1,
        }
    },
    {
        path: '/course/details/:id',
        component: () => import('@/views/course/details'),
        name: "courseDetails",
        meta: {
            title: '课程',
            classify: 'course',
            isShow: 1,
        },
    },
    {
        path: '/question/:id',
        component: () => import('@/views/question/index'),
        name: "question",
        meta: {
            title: '题库',
            classify: 'question',
            isShow: 1,
            keepAlive:true
        }
    },
    {
        path: '/question/result/:id',
        component: () => import('@/views/question/result'),
        name: "questionResult",
        meta: {
            title: '题库',
            classify: 'question',
            isShow: 1,
        }
    },
    {
        path: '/question/details/:id',
        component: () => import('@/views/question/details'),
        name: "questionDetails",
        meta: {
            title: '题库详情',
            classify: 'question',
            isShow: 1,
        },
    },
    {
        path: '/live/:id',
        component: () => import('@/views/live/index'),
        name: "live",
        meta: {
            title: '直播',
            classify: 'live',
            isShow: 1,
        }
    },
    {
        path: '/live/details/:id',
        component: () => import('@/views/live/details'),
        name: "liveDetails",
        meta: {
            title: '直播详情',
            classify: 'live',
            isShow: 1,
        },
    },
    {
        path: '/files/:id',
        component: () => import('@/views/files/index'),
        name: "files",
        meta: {
            title: '资料列表',
            classify: 'files',
            isShow: 1,
        }
    },
    {
        path: '/files/details/:id',
        component: () => import('@/views/files/details'),
        name: "filesDetails",
        meta: {
            title: '资料详情',
            classify: 'files',
            isShow: 1,
        },
    },
    {
        path: '/files/preview/:path',
        component: () => import('@/views/files/preview'),
        name: "preview",
        meta: {
            title: '资料预览',
            classify: 'preview',
            isShow: 1,
        },
    },
    {
        path: '/my',
        component: () => import('@/views/my/index'),
        name: "my",
        meta: {
            title: '我的',
            classify: 'my',
            isShow: 1,
        }
    },
    {
        path: '/my/question',
        component: () => import('@/views/my/question'),
        name: "myQuestion",
        meta: {
            title: '我的题库',
            classify: 'myQuestion',
            isShow: 1,
        }
    },
    {
        path: '/my/course',
        component: () => import('@/views/my/course'),
        name: "myCourse",
        meta: {
            title: '我的课程',
            classify: 'myCourse',
            isShow: 1,
        }
    },
    {
        path: '/my/live',
        component: () => import('@/views/my/live'),
        name: "myLive",
        meta: {
            title: '我的预约',
            classify: 'myLive',
            isShow: 1,
        }
    },
    {
        path: '/my/files',
        component: () => import('@/views/my/files'),
        name: "myFiles",
        meta: {
            title: '我的资料',
            classify: 'myFiles',
            isShow: 1,
        }
    },
    {
        path: '/my/recharge',
        component: () => import('@/views/my/recharge'),
        name: "myRecharge",
        meta: {
            title: '充值积分',
            classify: 'myRecharge',
            isShow: 1,
        }
    },
    {
        path: '/my/password',
        component: () => import('@/views/my/password'),
        name: "myPassword",
        meta: {
            title: '密码安全',
            classify: 'myPassword',
            isShow: 1,
        }
    },
    {
        path: '/retrievePassword',
        component: () => import('@/views/retrievePassword/retrievePassword'),
        name: "retrievePassword",
        meta: {
            title: '找回密码',
            isShow: 1,
        },
    },
]);

//过滤 isShow == 0
function filterByKey(arrayObj) {
    if (!arrayObj.length) {
        return []
    }
    return arrayObj.filter((item) => {
        return item["meta"]["isShow"] - 0 === 1
    })
}

export {
    routes
}