<template>
  <router-view class="app"></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  created() {

  },
  mounted() {

  }
}
</script>

<style>

</style>
