/**
 * @author: https://www.51xuediannao.com/
 * @update: 2021-09-23 12:02
 * API地址
 * http://rjd.rujiazaixian.com/doc/index.html
 */
const _CONFIG = Object.assign({
    "debug": true,
    "isNoData":false,
    "env":process.env.VUE_APP_ENV,//local development production
    "host": process.env.VUE_APP_HOST,
    "apiPath": process.env.VUE_APP_API,
    "cdn": "",
    "rootPath": "",
    "siteName": "儒嘉网校",
    "systemFullName": "",
    "shortName": "",
    "slogan":"",
    "pageSize":20,
    "loginRequiredTip":"您未登录不能查看",
    "prefix":"TMWXVIP",
}, {});

export {_CONFIG}